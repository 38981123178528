import type { AppState } from '@/bootstrap/state.ts';
import type { Selectors } from '@/bootstrap/selectors.ts';
import type { OnyxStockLoanComponent } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function selectElsStockLoanPortfolios(
  state: AppState,
  legId: string,
  selectors: Selectors,
): Pick<OnyxStockLoanComponent, 'lendPortfolio' | 'borrowPortfolio'> | null {
  const { productId, strategyId } = selectors.getLegData(state, legId);
  const product = selectors.getProduct(state, productId);

  if (!isElsProduct(product)) {
    return null;
  }

  const legWay = selectors.getLegWay(state, product.legId, selectors);
  const hedgePortfolio = product.stockLoanHedge?.portfolio;
  const { portfolioId } = selectors.getStrategyData(state, strategyId);

  switch (legWay) {
    case 'BUY':
      return { borrowPortfolio: hedgePortfolio, lendPortfolio: portfolioId };
    case 'SELL':
      return { borrowPortfolio: portfolioId, lendPortfolio: hedgePortfolio };
    default:
      return null;
  }
}
