import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

interface TraderBidLeg {
  legId: string;
  quoteId: string;
  unit: string;
  traderBid: number | undefined;
  bidIsTransparent: boolean;
}

export interface TraderBidModel {
  isEnabled: boolean;
  legs: TraderBidLeg[];
}
export function getTraderBidModel(
  state: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): TraderBidModel {
  const compositionLegs = selectors.getStrategyCompositionLegsData(state, strategyId, selectors);
  const legsQuotes: TraderBidLeg[] = compositionLegs.map(({ uuid, quoteId }) => {
    const { unit, traderBid } = selectors.getQuote(state, quoteId);
    const bidIsTransparent = selectors.getLegWay(state, uuid, selectors) === 'BUY';
    const areSalesPricesDisplayed = selectors.areSalesPricesDisplayed(state.ui, rfqId, selectors);
    return {
      legId: uuid,
      quoteId,
      unit: areSalesPricesDisplayed ? '' : (unit ?? ''),
      traderBid,
      bidIsTransparent,
    };
  });

  const isEnabled = selectors.areTraderPricesEnabled(state, rfqId, strategyId, selectors);
  return { isEnabled, legs: legsQuotes };
}
