import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { getAveragePriceTooltipMessage } from '../../../prices/quotes/getQuotesModel';

interface SalesAskLeg {
  legId: string;
  quoteId: string;
  unit: '';
  salesAsk: number | undefined;
  askIsTransparent: boolean;
  averagePrice: string | undefined;
  isAveragePriceTooltipSalesAskEnabled: boolean;
}

export interface SalesAskModel {
  isEnabled: boolean;
  legs: SalesAskLeg[];
}
export function getSalesAskModel(
  appState: AppState,
  rfqId: string,
  strategyId: string,
  selectors: Selectors,
): SalesAskModel {
  const compositionLegs = selectors.getStrategyCompositionLegsData(appState, strategyId, selectors);
  const isStatusAfterOrderBeingCrossed = selectors.isStatusAfterOrderBeingCrossed(
    appState,
    rfqId,
    selectors,
  );
  const legsQuotes: SalesAskLeg[] = compositionLegs.map(({ uuid, quoteId }) => {
    const { unit, salesAsk } = selectors.getQuote(appState, quoteId);
    const isBuyWay = selectors.getLegWay(appState, uuid, selectors) === 'BUY';
    const askIsTransparent = isBuyWay;
    const isListed = selectors.isListedLeg(appState, uuid, selectors);
    const { averagePrice } = selectors.getOrderByLegId(appState.orderData, rfqId, uuid) ?? {};
    const isAveragePriceTooltipSalesAskEnabled =
      isListed &&
      isBuyWay &&
      isStatusAfterOrderBeingCrossed &&
      unit === averagePrice?.unit &&
      salesAsk !== averagePrice?.value;

    return {
      legId: uuid,
      quoteId,
      unit: '',
      salesAsk,
      askIsTransparent,
      isAveragePriceTooltipSalesAskEnabled,
      averagePrice: getAveragePriceTooltipMessage(averagePrice?.value, averagePrice?.unit),
    };
  });

  const isEnabled = selectors.areTraderPricesEnabled(appState, rfqId, strategyId, selectors);

  return { isEnabled, legs: legsQuotes };
}
