import { selectors } from '@/bootstrap/selectors';
import { neosActionCreators } from '@/neos/business/neosActionCreators';

import { useAppSelector } from '@/bootstrap/hooks';
import { useDispatch } from 'react-redux';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput.tsx';
import { toUpperCase } from '@/util/string/stringUtils.ts';

interface PortfolioProps {
  strategyId: string;
  rfqId: string;
  isComponentInLegDescription?: boolean;
  labelPrefix?: string;
}

export function Portfolio({
  strategyId,
  rfqId,
  isComponentInLegDescription = false,
  labelPrefix,
}: PortfolioProps) {
  const dispatch = useDispatch();
  const { portfolioId } = useAppSelector(state => selectors.getStrategyData(state, strategyId));
  const { internal } = useAppSelector(state => selectors.getRfqData(state, rfqId));

  const onPortfolioIdChanged = (value: string | undefined) => {
    dispatch(neosActionCreators.strategyDataCrudActions.update(strategyId, { portfolioId: value }));
  };

  const isReadOnlyRfq = useAppSelector(state => selectors.isReadOnlyRfq(state, rfqId));
  const isReadOnlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isReadOnlyAtCurrentWorkflow(state, strategyId, selectors),
  );

  return (
    <>
      <label className={isComponentInLegDescription ? 'mb-0 me-3' : ''}>
        {labelPrefix} {internal ? 'Initiator Deal Ptf' : 'Portfolio'}
      </label>
      <NeosBlurInput
        readOnly={isReadOnlyRfq || isReadOnlyAtCurrentWorkflow}
        type="text"
        transform={toUpperCase}
        value={portfolioId || ''}
        className={`${isComponentInLegDescription ? '' : 'form-control-alt'}`}
        onBlur={event => onPortfolioIdChanged(event.target.value || undefined)}
        data-e2e="strategy-details-portfolio"
      />
    </>
  );
}
