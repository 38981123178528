import { actionCreators } from '@/bootstrap/actions';
import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import { thunks } from '@/bootstrap/thunks';
import {
  stockLoanTypes,
  tradingBusinessTypes,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type { Els } from '@/neos/business/neosModel';
import { NeosSelect } from '@/neos/components/share/NeosSelect/NeosSelect';
import { NeosBlurInput } from '@/neos/components/share/blurComponent/NeosBlurInput';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { useDispatch } from 'react-redux';
import { TppBookingId } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/TppBookingId.tsx';
import { ElsLendAndBorrowPortfolio } from '@/neos/components/rfq/strategies/strategy/features/elsLendAndBorrow/ElsLendAndBorrowPortfolio.tsx';
import { Portfolio } from '@/neos/components/rfq/strategyDetails/Portfolio/Portfolio.tsx';

interface ElsLendAndBorrowDescriptionProps {
  rfqId: string;
  strategyId: string;
  product: Els;
}

export function ElsLendAndBorrowDescription({
  product,
  rfqId,
  strategyId,
}: ElsLendAndBorrowDescriptionProps) {
  const dispatch = useDispatch();
  const isRfqReadonlyAtCurrentWorkflow = useAppSelector(state =>
    selectors.isRfqReadOnlyAtCurrentWorkflow(state, rfqId, selectors),
  );

  return (
    <div
      className="d-grid gap-y-1 gap-x-2 text-end align-items-center"
      style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', cursor: 'default' }}
      onMouseDown={e => e.stopPropagation()}
    >
      <label>Product Type</label>
      <NeosSelect
        addEmptyOption
        readOnly={isRfqReadonlyAtCurrentWorkflow}
        value={product.stockLoanHedge?.stockLoanType}
        options={stockLoanTypes}
        onChange={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'stockLoanType', value),
          );
        }}
        data-e2e="els-LendAndBorrow-productType"
      />

      <label>Trading Business</label>
      <NeosSelect
        addEmptyOption
        readOnly={isRfqReadonlyAtCurrentWorkflow}
        value={product.stockLoanHedge?.tradingBusiness}
        options={tradingBusinessTypes}
        onChange={value => {
          dispatch(
            thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'tradingBusiness', value),
          );
        }}
        data-e2e="els-LendAndBorrow-tradingBusiness"
      />

      <label>Maturity</label>
      <FlowDatePicker
        data-e2e="neos-lendAndBorrow-maturity"
        date={product.stockLoanHedge?.maturity ?? ''}
        maturities={[]}
        onChange={value => {
          dispatch(thunks.neos.createUpdateElsStockLoanHedgeThunk(product.uuid, 'maturity', value));
        }}
      />

      <ElsLendAndBorrowPortfolio />

      <label>FO Com</label>
      <NeosBlurInput
        value={product.hedgeComment}
        onBlur={event => {
          dispatch(
            actionCreators.neos.productCrudActions.update(product.uuid, {
              hedgeComment: event.target.value,
            }),
          );
        }}
        data-e2e="els-LendAndBorrow-hedgeComment"
      />

      <Portfolio strategyId={strategyId} rfqId={rfqId} labelPrefix="Strategy" />

      <TppBookingId rfqId={rfqId} product={product} />
    </div>
  );
}
