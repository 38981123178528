import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { FromOnyxMappers, ToOnyxMappers } from '@/neos/business/mappers';
import {
  isOnyxBasketProduct,
  isOnyxBasketUnderlying,
  type OnyxAsianPeriod,
  type OnyxBasketUnderlying,
  type OnyxProduct,
  type OnyxProductCommon,
  type OnyxProductUnderlying,
} from '../../../../../../../neos/business/neosOnyxModel';
import type { Feature } from '../../../feature/featureModel';
import type {
  LegPeriod,
  LegPeriodDates,
  OnyxBreakFeePeriod,
  OnyxProductEls,
  OnyxStockLoanHedge,
} from '../elsProductOnyxModel';
import {
  type AsianPeriod,
  type AsianProduct,
  type Els,
  hasFutureMaturity,
  isClsProduct,
  isCustomUnderlyingProduct,
  isDerivativeProduct,
  isDivSwapProduct,
  isElsProduct,
  isFutureLikeProduct,
  isFvaFixedKProduct,
  isListedProduct,
  isOptionLike,
  isOptionProduct,
  isProductWithAsianFields,
  isProductWithStrikeDateTenor,
  isVSwapProduct,
  type Product,
} from '../productModel';

export function mapToOnyxProductUnderlying(
  state: AppState,
  productId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxProductUnderlying | OnyxBasketUnderlying | undefined {
  const product = selectors.getProduct(state, productId);
  return isCustomUnderlyingProduct(product)
    ? mappers.mapToOnyxCustomUnderlyingProduct(state, productId, selectors).underlying
    : mappers.mapToOnyxDerivativeProduct(state, productId, selectors, mappers).underlying;
}

export function mapToOnyxProduct(
  state: AppState,
  strategyId: string,
  productId: string,
  selectors: Selectors,
  mappers: ToOnyxMappers,
): OnyxProduct {
  const product = selectors.getProduct(state, productId);
  const defaultLotSize = isListedProduct(product) ? undefined : 1;
  const underlying = mappers.mapToOnyxProductUnderlying(state, productId, selectors, mappers);

  const extraFeaturesOrMandatoryFeature = mappers.mapToOnyxProductFeatures(
    state,
    strategyId,
    selectors,
    mappers,
  );

  const { lowerStrike, upperStrike } = mappers.mapToOnyxUpperLowerStrike(
    state,
    productId,
    selectors,
  );

  const commonProductField: OnyxProductCommon = {
    discriminator: product.subFamily,
    lotSize: product.lotSize || defaultLotSize,
    negotiation: mappers.mapToOnyxNegotiation(product),
    noTaxCollection: product.noTaxCollection,
    clientTaxRate: product.clientTaxRate,
    maturity:
      isDerivativeProduct(product) || isCustomUnderlyingProduct(product)
        ? product.maturity
        : undefined,
    futureMaturity: hasFutureMaturity(product) ? product.futureMaturity : undefined,
    expectedN: isVSwapProduct(product) ? product.expectedN : undefined,
    deliveryType: product.deliveryType,
    optionType: isOptionLike(product) ? product.type : undefined,
    optionStyle: isOptionLike(product) ? product.style : undefined,
    optionFlexType: isOptionLike(product) ? product.flex : undefined,
    observableType: isOptionProduct(product) ? product.observableType : undefined,
    strike: mappers.mapToOnyxStrike(state, productId, selectors),
    lowerStrike,
    upperStrike,
    pointValue:
      isFutureLikeProduct(product) || product.subFamily === 'FX_FORWARD'
        ? product.pointValue
        : undefined,
    forwardDrift: isFvaFixedKProduct(product) ? product.forwardDrift : undefined,
    forwardInterestRate: isFvaFixedKProduct(product)
      ? { value: product.forwardInterestRate, unit: '%' }
      : undefined,
    swapCurrency: isElsProduct(product) ? (product.swapCurrency ?? undefined) : undefined,
    startDate: isDivSwapProduct(product) ? product.startDate : undefined,
    strikeDate: isProductWithStrikeDateTenor(product) ? product.strikeDate : undefined,
    strikeTenor: isProductWithStrikeDateTenor(product) ? product.strikeTenor : undefined,
    clsType: isClsProduct(product) ? product.clsType : undefined,
    accrual: isClsProduct(product) ? product.accrual : undefined,
    generateFrom: isClsProduct(product) ? product.generateFrom : undefined,
    fixedDay: isClsProduct(product) ? product.fixedDay : undefined,
    rateReset: isClsProduct(product) ? product.rateReset : undefined,
    resetMode: isClsProduct(product) ? product.resetMode : undefined,
    brokenPeriod: isClsProduct(product) ? product.brokenPeriod : undefined,
    conventionDay: isClsProduct(product) ? product.conventionDay : undefined,
    effectiveDate: isClsProduct(product) ? product.effectiveDate : undefined,
    rateSchedulePeriods: isClsProduct(product)
      ? mapToOnyxLegPeriod(product.ratePeriods)
      : undefined,
    maturityTenor:
      isDerivativeProduct(product) || isCustomUnderlyingProduct(product)
        ? product.maturityTenor
        : undefined,
    period: isProductWithAsianFields(product) ? mapToOnyxAsianPeriod(product.period) : undefined,
    strikeType: isProductWithAsianFields(product) ? product.strikeType : undefined,
    averageDisruptionDate: isProductWithAsianFields(product)
      ? product.averageDisruptionDate
      : undefined,
    businessDayConvention: isProductWithAsianFields(product)
      ? product.businessDayConvention
      : undefined,
    isScheduleObsolete: isClsProduct(product) ? product.isScheduleObsolete : undefined,
    ...extraFeaturesOrMandatoryFeature,
    ...mapToOnyxElsProduct(state, product, mappers, selectors),
  };

  if (isOnyxBasketUnderlying(underlying)) {
    return {
      ...commonProductField,
      underlying,
    };
  }
  return {
    ...commonProductField,
    underlying,
  };
}

function mapToOnyxPeriodFrequency(
  frequency: AsianPeriod['frequency'],
): OnyxAsianPeriod['frequency'] | undefined {
  switch (frequency) {
    case 'YEARLY':
      return 'P1Y';
    case 'MONTHLY':
      return 'P1M';
    case 'DAILY':
      return 'P1D';
  }

  return undefined;
}

export function mapToOnyxAsianPeriod(period: AsianProduct['period']): OnyxProduct['period'] {
  if (!period) {
    return undefined;
  }

  return {
    endDate: period.endDate,
    startDate: period.startDate,
    dates: period.dates
      .map(({ uuid, ...rest }) => ({ ...rest }))
      .filter(date => date.date !== undefined),
    frequency: mapToOnyxPeriodFrequency(period.frequency),
    includeEndDate: period.includeEndDate,
  };
}

export function mapToOnyxLegPeriod<T extends LegPeriodDates>(
  legPeriod: LegPeriod<T>[] | undefined,
): T[] | undefined {
  return legPeriod
    ?.map(({ dates }) => dates)
    .filter(dates => Object.values(dates).some(date => Boolean(date)));
}

function mapToOnyxElsProduct(
  state: AppState,
  product: Product,
  mappers: ToOnyxMappers,
  selectors: Selectors,
): OnyxProductEls | undefined {
  if (isElsProduct(product)) {
    return {
      elsType: product.elsType,
      calculationMethod: product.calculationMethod,
      lookbackPeriod: product.lookbackPeriod,
      observationShift: product.observationShift,
      paymentDelay: product.paymentDelay,
      lockout: product.lockout,
      generateFrom: product.generateFrom,
      equityResetType: product.equityResetType,
      rateReset: product.rateReset,
      brokenPeriod: product.brokenPeriod,
      fixedDay: product.wRateResetOnEach,
      conventionDay: product.conventionDay,
      effectiveDate: product.effectiveDate,
      effectiveDateOffset: product.effectiveDateOffset,
      rateSchedulePeriods: mapToOnyxLegPeriod(product.ratePeriods),
      equitySchedulePeriods: mapToOnyxLegPeriod(product.equityPeriods),
      roleDefinition: product.roleDefinition,
      calculationAgent: product.calculationAgent,
      determiningParty: product.determiningParty,
      hedgingParty: product.hedgingParty,
      rateSpreadAdjustment: product.rateSpreadAdjustment,
      dividendSpreadAdjustment: product.dividendSpreadAdjustment,
      dividendPriceType: product.dividendPriceType,
      linearInterpolation: product.linearInterpolation,
      lookthroughDR: product.lookthroughDR,
      rightToSubstituteScope: product.rightToSubstituteScope,
      rightToSubstituteConditions: product.rightToSubstituteConditions,
      relatedExchange: product.relatedExchange,
      termNotice: product.termNotice,
      clientTermNotice: product.clientTermNotice,
      dailyMinSize:
        product.dailyMinSize !== undefined
          ? { value: product.dailyMinSize, unit: product.swapCurrency, type: 'CCY' }
          : undefined,
      dailyMaxSize:
        product.dailyMaxSize !== undefined
          ? { value: product.dailyMaxSize, unit: product.swapCurrency, type: 'CCY' }
          : undefined,
      secondaryMarketAllowed: product.secondaryMarketAllowed,
      componentSecurityIndexAnnex: product.componentSecurityIndexAnnex,
      breakFeeElection: product.breakFeeElection,
      breakFeePeriods: product.breakFeePeriods?.map(
        (period): OnyxBreakFeePeriod => ({
          breakFeePeriodType: period.type,
          fee: period.fee,
          startDate: period.startDate,
          endDate: period.endDate,
          nominalMin:
            period.nominalMin !== undefined
              ? { value: period.nominalMin, unit: product.swapCurrency, type: 'CCY' }
              : undefined,
          nominalMax:
            period.nominalMax !== undefined
              ? { value: period.nominalMax, unit: product.swapCurrency, type: 'CCY' }
              : undefined,
        }),
      ),
      basisType: product.basisType,
      compoundRate: product.compoundRate,
      dealType: product.dealType,
      electionDate: product.electionDate,
      electionFee: product.electionFee,
      settlementMethodElection: product.settlementMethodElection,
      terminationConditions: product.terminationConditions,
      terminationRights: product.terminationRights,
      terminationType: product.terminationType,
      valuationType: product.valuationType,
      localTaxes: product.localTaxes,
      declaredCashDiv:
        product.declaredCashDiv !== undefined
          ? { value: product.declaredCashDiv, unit: '%' }
          : undefined,
      specialDividends: product.specialDividends,
      rateFixingOffset: product.rateFixingOffset,
      brokenPeriodPosition: product.brokenPeriodPosition,
      derogateRateFixingOffset: product.derogateRateFixingOffset,
      isScheduleObsolete: product.isScheduleObsolete,
      hedgeComment: product.hedgeComment,
      stockLoanHedge: product.stockLoanHedge
        ? {
            maturity: product.stockLoanHedge?.maturity,
            portfolio: product.stockLoanHedge?.portfolio,
            stockLoanType: product.stockLoanHedge?.stockLoanType,
            tradingBusiness: product.stockLoanHedge?.tradingBusiness,
            bookingApplication: product.stockLoanHedge?.bookingApplication,
            bookingId: product.stockLoanHedge?.bookingId,
            stockLoanComponents: mapToStockLoanComponents(state, product, mappers, selectors),
          }
        : undefined,
    };
  }
}

export interface MapFromOnyxProductResult {
  product: Product;
  features: Feature[];
}

interface MapFromOnyxProductParameters {
  onyxProduct: OnyxProduct;
  strategyId: string;
  legId: string;
  productId: string;
  mappers: FromOnyxMappers;
}

export function mapFromOnyxProduct({
  onyxProduct,
  strategyId,
  legId,
  productId,
  mappers,
}: MapFromOnyxProductParameters): MapFromOnyxProductResult {
  const product: Product = (() => {
    if (isOnyxBasketProduct(onyxProduct)) {
      switch (onyxProduct.discriminator) {
        case 'ELS':
        case 'PRS':
        case 'TRS': {
          const elsProductType = onyxProduct.discriminator;
          return mappers.mapFromOnyxProductEls(
            legId,
            onyxProduct,
            productId,
            elsProductType,
            mappers,
            services,
          );
        }
      }
      throw new Error('Only ELS should have a basket product');
    }

    switch (onyxProduct.discriminator) {
      case 'OPTION':
        return mappers.mapFromOnyxProductOption(legId, onyxProduct, productId, mappers);
      case 'OPTION_ON_FUTURE':
        return mappers.mapFromOnyxProductOptionOnFuture(legId, onyxProduct, productId, mappers);
      case 'EUROPEAN_SPREAD_OPTION':
        return mappers.mapFromOnyxProductEuropeanOption(legId, onyxProduct, productId, mappers);
      case 'ASIAN_OPTION':
        return mappers.mapFromOnyxProductAsianOption(legId, onyxProduct, productId, mappers);
      case 'ASIAN_SPREAD_OPTION':
        return mappers.mapFromOnyxProductAsianSpreadOption(legId, onyxProduct, productId, mappers);
      case 'FIXED_STRIKE_FVA':
        return mappers.mapFromOnyxFvaFixedK(legId, onyxProduct, productId, mappers);
      case 'FLOATING_STRIKE_FVA':
        return mappers.mapFromOnyxFvaFloatingK(legId, onyxProduct, productId, mappers);
      case 'STOCK':
        return mappers.mapFromOnyxProductStock(legId, onyxProduct, productId, mappers);
      case 'FUTURE':
        return mappers.mapFromOnyxProductFuture(legId, onyxProduct, productId, mappers);
      case 'TOTAL_RETURN_FUTURE':
        return mappers.mapFromOnyxProductTotalReturnFuture(legId, onyxProduct, productId, mappers);
      case 'DIVIDEND_FUTURE':
        return mappers.mapFromOnyxProductDividendFuture(legId, onyxProduct, productId, mappers);
      case 'VAR_SWAP':
      case 'VOL_SWAP':
      case 'CROSS_CORRIDOR_VAR_SWAP':
      case 'CROSS_CORRIDOR_VOL_SWAP':
      case 'MONO_CORRIDOR_VAR_SWAP':
      case 'MONO_CORRIDOR_VOL_SWAP':
        return mappers.mapFromOnyxProductVSwap(
          legId,
          onyxProduct,
          productId,
          onyxProduct.discriminator,
          mappers,
        );
      case 'ELS':
      case 'PRS':
      case 'TRS': {
        const elsProductType = onyxProduct.discriminator;
        return mappers.mapFromOnyxProductEls(
          legId,
          onyxProduct,
          productId,
          elsProductType,
          mappers,
          services,
        );
      }
      case 'CLS':
        return mappers.mapFromOnyxProductCls(legId, onyxProduct, productId, mappers, services);

      case 'DIV_SWAP':
      case 'SWAPTION':
        return mappers.mapFromOnyxProductDivSwapSwaption(
          legId,
          onyxProduct.discriminator,
          onyxProduct,
          productId,
          mappers,
        );
      case 'CORREL_SWAP':
      case 'FX_OPTION':
      case 'FX_VOL_SWAP':
      case 'FX_VAR_SWAP':
      case 'BASKET_OPTION':
      case 'FX_FVA':
      case 'CUSTOM':
        return mappers.mapFromOnyxCustomProduct(
          legId,
          onyxProduct.discriminator,
          onyxProduct,
          productId,
          mappers,
        );
      case 'FX_DIGITAL_OPTION':
        return mappers.mapFromOnyxFxDigitalOptionProduct(legId, onyxProduct, productId, mappers);
      case 'FX_FORWARD':
        return mappers.mapFromOnyxFxForwardProduct(legId, onyxProduct, productId, mappers);
      case 'DIGITAL_OPTION':
        return mappers.mapFromOnyxDigitalOptionProduct(legId, onyxProduct, productId, mappers);
      default:
        throw new Error(`Product Type: ${onyxProduct.discriminator} not supported!`);
    }
  })();

  const features: Feature[] = mappers.mapFromOnyxFeatures(onyxProduct, strategyId);

  return {
    product,
    features,
  };
}

function mapToStockLoanComponents(
  state: AppState,
  product: Els,
  mappers: ToOnyxMappers,
  selectors: Selectors,
): OnyxStockLoanHedge['stockLoanComponents'] {
  const elsStockLoanPortfolios = selectors.selectElsStockLoanPortfolios(
    state,
    product.legId,
    selectors,
  );

  return product.stockLoanHedge?.stockLoanComponents
    .filter(composition => composition.underlyingId !== undefined)
    .map(({ underlyingId, ...rest }) => ({
      ...rest,
      underlying: mappers.mapToOnyxUnderlying(state, underlyingId, selectors),
      lendPortfolio: elsStockLoanPortfolios?.lendPortfolio,
      borrowPortfolio: elsStockLoanPortfolios?.borrowPortfolio,
    }));
}
