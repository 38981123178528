import { createAddNewAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createAddNewAsianOptionPeriodDateThunk';
import { createAddNewElsBasketCompositionItemThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createAddNewElsBasketCompositionItemThunk';
import { createAddNewElsStockLoanComponentThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createAddNewElsStockLoanComponentThunk';
import { createChangeElsBasketExecFeeKindThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createChangeElsBasketExecFeeKindThunk';
import { createDeleteAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createDeleteAsianOptionPeriodDateThunk';
import { createCleanBasketCompositionExecFees } from '@/neos/business/rfq/strategy/leg/product/thunks/createDeleteKeyInBasketCompositionThunk';
import { createExportElsBasketCompositionThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createExportElsBasketCompositionThunk';
import { createToggleElsLendAndBorrowFeatureThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createToggleElsLendAndBorrowFeatureThunk';
import { createUpdateAsianOptionPeriodDateThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateAsianOptionPeriodDateThunk';
import { createUpdateBasketCompositionFeesUnit } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateBasketCompositionFeesUnitThunk';
import { createUpdateElsBasketCompositionThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateElsBasketCompositionThunk';
import { createUpdateElsBasketThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateElsBasketThunk';
import { createUpdateElsStockLoanComponentThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateElsStockLoanComponentThunk';
import { createUpdateElsStockLoanHedgeThunk } from '@/neos/business/rfq/strategy/leg/product/thunks/createUpdateElsStockLoanHedgeThunk';
import { createDefaultBasketProductThunk } from '@/neos/business/rfq/strategy/leg/thunks/defaultBasketProductThunk';
import { createDefaultOnChangeBasketCurrencyThunk } from '@/neos/business/rfq/strategy/leg/thunks/defaultOnChangeBasketCurrencyThunk';
import { elsProductThunks } from '@/neos/business/rfq/strategy/leg/product/els/thunks';

export const productThunks = {
  ...elsProductThunks,
  createAddNewAsianOptionPeriodDateThunk,
  createUpdateAsianOptionPeriodDateThunk,
  createDeleteAsianOptionPeriodDateThunk,
  createToggleElsLendAndBorrowFeatureThunk,
  createUpdateElsStockLoanHedgeThunk,
  createUpdateElsStockLoanComponentThunk,
  createAddNewElsStockLoanComponentThunk,
  createExportElsBasketCompositionThunk,
  createDefaultOnChangeBasketCurrencyThunk,
  createDefaultBasketProductThunk,
  createAddNewElsBasketCompositionItemThunk,
  createUpdateElsBasketCompositionThunk,
  createChangeElsBasketExecFeeKindThunk,
  createCleanBasketCompositionExecFees,
  createUpdateBasketCompositionFeesUnit,
  createUpdateElsBasketThunk,
};
