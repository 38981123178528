import type { FromOnyxMappers } from '@/neos/business/mappers';
import { convertNullToUndefined } from '@/util/undefinedAndNull/convertNullToUndefined';
import type { Els, StockLoanComponent } from '../../productModel';
import type { OnyxProduct } from '../../productOnyxModel';

import type { Services } from '@/bootstrap/services';
import type { LegPeriod, LegPeriodDates, RateLegPeriodDates } from '../../elsProductOnyxModel';

export function mapFromOnyxProductEls(
  legId: string,
  onyxProduct: OnyxProduct,
  productId: string,
  elsProductType: 'ELS' | 'PRS' | 'TRS',
  mappers: FromOnyxMappers,
  services: Services,
): Els {
  return {
    subFamily: elsProductType,
    swapCurrency: convertNullToUndefined(onyxProduct.swapCurrency),
    observationShift: convertNullToUndefined(onyxProduct.observationShift),
    lookbackPeriod: convertNullToUndefined(onyxProduct.lookbackPeriod),
    paymentDelay: convertNullToUndefined(onyxProduct.paymentDelay),
    lockout: convertNullToUndefined(onyxProduct.lockout),
    calculationMethod: convertNullToUndefined(onyxProduct.calculationMethod),
    strikeDate: convertNullToUndefined(onyxProduct.strikeDate),
    strikeTenor: convertNullToUndefined(onyxProduct.strikeTenor),
    maturityTenor: convertNullToUndefined(onyxProduct.maturityTenor),
    elsType: convertNullToUndefined(onyxProduct.elsType),
    ...mappers.mapFromOnyxProductCommon(legId, onyxProduct, productId),
    ...mappers.mapFromOnyxOtcNegotiation(),
    ...mappers.mapFromElsDerivativeOnyxProduct(onyxProduct, mappers),
    hasCustomUnderlying: false,
    generateFrom: convertNullToUndefined(onyxProduct.generateFrom),
    equityResetType: convertNullToUndefined(onyxProduct.equityResetType),
    rateReset: convertNullToUndefined(onyxProduct.rateReset),
    brokenPeriod: convertNullToUndefined(onyxProduct.brokenPeriod),
    wRateResetOnEach: convertNullToUndefined(onyxProduct.fixedDay),
    conventionDay: convertNullToUndefined(onyxProduct.conventionDay),
    effectiveDate: convertNullToUndefined(onyxProduct.effectiveDate),
    effectiveDateOffset: convertNullToUndefined(onyxProduct.effectiveDateOffset),
    ratePeriods: mapFromOnyxLegPeriod(
      onyxProduct.rateSchedulePeriods as RateLegPeriodDates[],
      services,
    ),
    equityPeriods: mapFromOnyxLegPeriod(onyxProduct.equitySchedulePeriods, services),

    roleDefinition: convertNullToUndefined(onyxProduct.roleDefinition),
    calculationAgent: convertNullToUndefined(onyxProduct.calculationAgent),
    determiningParty: convertNullToUndefined(onyxProduct.determiningParty),
    hedgingParty: convertNullToUndefined(onyxProduct.hedgingParty),
    rateSpreadAdjustment: convertNullToUndefined(onyxProduct.rateSpreadAdjustment),
    dividendSpreadAdjustment: convertNullToUndefined(onyxProduct.dividendSpreadAdjustment),
    dividendPriceType: convertNullToUndefined(onyxProduct.dividendPriceType),
    linearInterpolation: convertNullToUndefined(onyxProduct.linearInterpolation),
    lookthroughDR: convertNullToUndefined(onyxProduct.lookthroughDR),
    rightToSubstituteScope: convertNullToUndefined(onyxProduct.rightToSubstituteScope),
    rightToSubstituteConditions: convertNullToUndefined(onyxProduct.rightToSubstituteConditions),
    relatedExchange: convertNullToUndefined(onyxProduct.relatedExchange),
    termNotice: convertNullToUndefined(onyxProduct.termNotice),
    clientTermNotice: convertNullToUndefined(onyxProduct.clientTermNotice),
    dailyMinSize: convertNullToUndefined(onyxProduct.dailyMinSize?.value),
    dailyMaxSize: convertNullToUndefined(onyxProduct.dailyMaxSize?.value),
    secondaryMarketAllowed: convertNullToUndefined(onyxProduct.secondaryMarketAllowed),
    componentSecurityIndexAnnex: convertNullToUndefined(onyxProduct.componentSecurityIndexAnnex),
    breakFeeElection: convertNullToUndefined(onyxProduct.breakFeeElection),
    breakFeePeriods: onyxProduct.breakFeePeriods?.map(period => ({
      id: services.idCreator.createId(),
      type: period.breakFeePeriodType,
      fee: period.fee,
      startDate: period.startDate,
      endDate: period.endDate,
      nominalMin: period.nominalMin?.value,
      nominalMax: period.nominalMax?.value,
    })),
    basisType: convertNullToUndefined(onyxProduct.basisType),
    compoundRate: convertNullToUndefined(onyxProduct.compoundRate),
    dealType: convertNullToUndefined(onyxProduct.dealType),
    electionDate: convertNullToUndefined(onyxProduct.electionDate),
    electionFee: convertNullToUndefined(onyxProduct.electionFee),
    settlementMethodElection: convertNullToUndefined(onyxProduct.settlementMethodElection),
    terminationConditions: convertNullToUndefined(onyxProduct.terminationConditions),
    terminationRights: convertNullToUndefined(onyxProduct.terminationRights),
    terminationType: convertNullToUndefined(onyxProduct.terminationType),
    valuationType: convertNullToUndefined(onyxProduct.valuationType),
    localTaxes: convertNullToUndefined(onyxProduct.localTaxes),
    declaredCashDiv: convertNullToUndefined(onyxProduct.declaredCashDiv?.value),
    rateFixingOffset: convertNullToUndefined(onyxProduct.rateFixingOffset),
    specialDividends: convertNullToUndefined(onyxProduct.specialDividends),
    brokenPeriodPosition: convertNullToUndefined(onyxProduct.brokenPeriodPosition),
    derogateRateFixingOffset: convertNullToUndefined(onyxProduct.derogateRateFixingOffset),
    isScheduleObsolete: convertNullToUndefined(onyxProduct.isScheduleObsolete),
    hedgeComment: onyxProduct.hedgeComment ?? undefined,
    stockLoanHedge: mapFromStockLoanHedge(onyxProduct.stockLoanHedge),
  };
}

export function mapFromStockLoanHedge(
  onyxStockLoanHedge: OnyxProduct['stockLoanHedge'],
): Els['stockLoanHedge'] {
  if (!onyxStockLoanHedge) {
    return undefined;
  }

  const stockLoanComponents: StockLoanComponent[] = onyxStockLoanHedge.stockLoanComponents
    ? onyxStockLoanHedge.stockLoanComponents.map(component => ({
        uuid: component.uuid ?? undefined,
        allIn: component.allIn ?? undefined,
        dividendRequirement: component.dividendRequirement ?? undefined,
        fees: component.fees ?? undefined,
        quantity: component.quantity ?? undefined,
        underlyingId: component.underlying?.id ?? undefined,
      }))
    : [];

  return {
    maturity: onyxStockLoanHedge.maturity ?? undefined,
    portfolio: onyxStockLoanHedge.portfolio ?? undefined,
    stockLoanType: onyxStockLoanHedge.stockLoanType ?? undefined,
    tradingBusiness: onyxStockLoanHedge.tradingBusiness ?? undefined,
    bookingId: onyxStockLoanHedge.bookingId ?? undefined,
    bookingApplication: onyxStockLoanHedge.bookingApplication ?? undefined,
    stockLoanComponents,
  };
}

export function mapFromOnyxLegPeriod<T extends LegPeriodDates>(
  periodDates: T[] | undefined | null,
  services: Services,
): LegPeriod<T>[] | undefined {
  return convertNullToUndefined(periodDates)?.map(dates => ({
    uuid: services.idCreator.createId(),
    dates,
  }));
}
